// zustand store with persist

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { GetClientList } from "../../../../../server/src/types";
import { ShownColumns } from "./types";

type AdminStoreType = {
  selectedTiers: NonNullable<GetClientList["query"]["tiers"]>;
  showDisabled: boolean;
  selectedSalesPeople: NonNullable<
    GetClientList["query"]["selectedSalesPeople"]
  >;
  selectedIndustries: NonNullable<GetClientList["query"]["selectedIndustries"]>;
  shownColumns: ShownColumns;

  setSelectedTiers: (tiers: string[]) => void;
  setShowDisabled: (showDisabled: boolean) => void;
  setSelectedSalesPeople: (
    selectedSalesPeople: GetClientList["query"]["selectedSalesPeople"],
  ) => void;
  setSelectedIndustries: (
    selectedIndustries: GetClientList["query"]["selectedIndustries"],
  ) => void;
  setShownColumns: (shownColumns: ShownColumns) => void;
};

export const useAdminStore = create<AdminStoreType>()(
  persist(
    (set, get) => ({
      selectedTiers: [
        "ADVANCED",
        "BASIC",
        "PRO",
        "FREE",
        "ENTERPRISE",
        "SPECIAL",
      ],
      showDisabled: false,
      selectedSalesPeople: [
        "Nejc",
        "Andraz",
        "Anze",
        "Marcel",
        "Niko",
        "Marko",
        "Luka",
        "Matevz",
        "Nezmah",
        "Brez",
        "Lukas",
      ],
      selectedIndustries: [
        "Kozmetika",
        "Brivci",
        "Frizerstvo",
        "Bowling",
        "Wellness",
        "TrampolinPark",
        "Savne",
        "TenisIgrisca",
        "SportnaIgrisca",
        "Fitnes",
        "OsebnoTrenerstvo",
        "NajemProstora",
        "Karting",
        "Hoteli",
        "PrivatKlinike",
        "Masaze",
        "PasjiSalon",
        "Optika",
        "Zobozdravstvo",
        "Ostalo",
        "Avtoservisi",
        "Psihoterapija",
      ],
      shownColumns: ["Ime podjetja", "ID", "Račun ustvarjen"],

      setSelectedTiers: (tiers) => set({ selectedTiers: tiers }),
      setShowDisabled: (showDisabled) => set({ showDisabled }),
      setSelectedSalesPeople: (selectedSalesPeople) =>
        set({ selectedSalesPeople }),
      setSelectedIndustries: (selectedIndustries) =>
        set({ selectedIndustries }),
      setShownColumns: (shownColumns) => set({ shownColumns }),
    }),
    {
      name: "admin-filter",
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["searchQuery", "page"].includes(key),
          ),
        ),
    },
  ),
);
