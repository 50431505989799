import { api } from "@/lib/api-client";
import { notifications } from "@mantine/notifications";
import { GetClientList } from "../../../../../../server/src/types";
import { useAdminStore } from "../admin.store";
import { useSearchParams } from "react-router";

export const useClients = () => {
  const [searchParams, _] = useSearchParams();

  const page = parseInt(searchParams.get("page") || "1");
  const orderBy =
    (searchParams.get("ob") as
      | "businessName"
      | "dateCreated"
      | "lastAppointmentDate"
      | "billingCycleDate") || "dateCreated";
  const orderDirection = (searchParams.get("od") as "asc" | "desc") || "desc";
  const searchQuery = searchParams.get("q") || "";

  const {
    selectedIndustries,
    selectedSalesPeople,
    selectedTiers,
    showDisabled,
  } = useAdminStore((store) => store);

  // API CALLS
  const { data, isLoading, refetch, isFetching } = api.admin.useClientList({
    orderBy,
    page,
    orderDirection,
    perPage: 10,
    searchQuery,
    tiers: selectedTiers,
    selectedIndustries,
    selectedSalesPeople,
    showDisabled,
  });

  console.log(isLoading, isFetching);

  const { mutateAsync: updateClient } = api.admin.useUpdateClientDetails();

  const handleChangeSalesPersonForClient = async ({
    clientId,
    salesPerson,
  }: {
    clientId: number;
    salesPerson: GetClientList["response"]["salesPeople"][number];
  }) => {
    try {
      await updateClient({ clientId, body: { salesPerson } });

      refetch();

      notifications.show({
        message: "Uspešno posodobljeno",
        color: "green",
      });
    } catch (e) {
      notifications.show({
        title: "Napaka",
        message: "Napaka pri posodabljanju",
        color: "red",
      });
    }
  };

  return {
    data,
    isLoading: isLoading || isFetching,
    handleChangeSalesPersonForClient,
  };
};
