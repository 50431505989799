import { Select, Table } from "@mantine/core";

import dayjs from "dayjs";
import { GetClientList } from "../../../../../../server/src/types";

import LoadingSpinner from "@/Components/Dashboard/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router";
import { useAdminStore } from "../admin.store";
import classes from "./ClientsTable.module.css";
import { Snippet } from "@heroui/react";

export const ClientsTable = ({
  data,
  handleChangeSalesPersonForClient,
  isLoading,
}: {
  data: GetClientList["response"] | undefined;
  handleChangeSalesPersonForClient: ({
    clientId,
    salesPerson,
  }: {
    clientId: number;
    salesPerson: GetClientList["response"]["salesPeople"][number];
  }) => void;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const { shownColumns } = useAdminStore((state) => state);

  return (
    <>
      <Table.ScrollContainer minWidth={200}>
        <Table
          styles={{
            td: {
              cursor: "pointer",
            },
          }}
          className={classes.table}
          w={"100%"}
        >
          <Table.Thead>
            <Table.Tr>
              {shownColumns.includes("ID") && <Table.Th>ID</Table.Th>}
              {shownColumns.includes("Ime podjetja") && (
                <Table.Th>Ime podjetja</Table.Th>
              )}
              {shownColumns.includes("Račun ustvarjen") && (
                <Table.Th>Račun ustvarjen</Table.Th>
              )}
              {shownColumns.includes("Paket") && <Table.Th>Paket</Table.Th>}
              {shownColumns.includes("Zadnja aktivnost") && (
                <Table.Th>Zadnja aktivnost</Table.Th>
              )}
              {shownColumns.includes("Datum obračuna") && (
                <Table.Th>Datum obračuna</Table.Th>
              )}
              {shownColumns.includes("Admin email") && (
                <Table.Th>Admin email</Table.Th>
              )}
              {shownColumns.includes("Admin GSM") && (
                <Table.Th>Admin gsm</Table.Th>
              )}
              {shownColumns.includes("Sales guy") && (
                <Table.Th>Sales guy</Table.Th>
              )}
              {shownColumns.includes("Client language") && (
                <Table.Th>Jezik računa</Table.Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {data?.clients.map((client, index) => {
              return (
                <Table.Tr
                  key={index}
                  onClick={() => navigate("client/" + client.clientId)}
                >
                  {shownColumns.includes("ID") && (
                    <Table.Td>{client.clientId}</Table.Td>
                  )}
                  {shownColumns.includes("Ime podjetja") && (
                    <Table.Td>
                      <Snippet
                        symbol=""
                        variant="bordered"
                        className="border-0"
                        size="sm"
                      >
                        {client.businessName}
                      </Snippet>
                    </Table.Td>
                  )}
                  {shownColumns.includes("Račun ustvarjen") && (
                    <Table.Td>
                      {dayjs(client.clientDateCreated).format("DD. MM. YYYY.")}
                    </Table.Td>
                  )}
                  {shownColumns.includes("Paket") && (
                    <Table.Td>{client.FK_pricingTierId}</Table.Td>
                  )}
                  {shownColumns.includes("Zadnja aktivnost") && (
                    <Table.Td>
                      {client.lastAppointmentDate
                        ? `${dayjs(client.lastAppointmentDate).format(
                            "DD. MM.",
                          )} (${dayjs().diff(
                            client.lastAppointmentDate,
                            "days",
                          )} dni)`
                        : "Brez"}
                    </Table.Td>
                  )}
                  {shownColumns.includes("Datum obračuna") && (
                    <Table.Td>{client.billingCycleDate}</Table.Td>
                  )}
                  {shownColumns.includes("Admin email") && (
                    <Table.Td>
                      <Snippet
                        symbol=""
                        variant="bordered"
                        className="border-0"
                        size="sm"
                      >
                        {client.adminEmail}
                      </Snippet>
                    </Table.Td>
                  )}
                  {shownColumns.includes("Admin GSM") && (
                    <Table.Td>
                      <Snippet
                        symbol=""
                        variant="bordered"
                        className="border-0"
                        size="sm"
                      >
                        {client.adminPhone}
                      </Snippet>
                    </Table.Td>
                  )}
                  {shownColumns.includes("Sales guy") && (
                    <Table.Td onClick={(e) => e.stopPropagation()}>
                      <Select
                        allowDeselect={false}
                        value={client.salesPerson}
                        data={data.salesPeople}
                        onChange={(e) => {
                          if (!e) return;
                          handleChangeSalesPersonForClient({
                            clientId: client.clientId,
                            salesPerson:
                              e as GetClientList["response"]["salesPeople"][number],
                          });
                        }}
                        size={"xs"}
                        w={"15ch"}
                      ></Select>
                    </Table.Td>
                  )}
                  {shownColumns.includes("Client language") && (
                    <Table.Td>{client.preferred_language}</Table.Td>
                  )}
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      {isLoading && (
        <div className={classes.loadingOverlay}>
          <LoadingSpinner></LoadingSpinner>
        </div>
      )}
    </>
  );
};
