import LimeDrawer from "@/Components/LimeDrawer/LimeDrawer";
import { Color } from "@/types/colors";
import { Switch } from "@mantine/core";
import { useState } from "react";
import { RiArrowDownLine, RiListSettingsFill } from "react-icons/ri";
import { GetClientList } from "../../../../../../server/src/types";
import { useForm } from "@mantine/form";
import { useAdminStore } from "../admin.store";
import { columnsArray } from "../types";
import { LimeMultiSelect } from "@/Components/LimeMultiSelect";
import { Button } from "@heroui/react";
import { useSearchParams } from "react-router";
import { useDebouncedCallback } from "@mantine/hooks";
import { LimeInput } from "@/Components/NextBase/LimeInput";
import { LimeSelect } from "@/Components/NextBase/LimeSelect";
import { LimeSwitch } from "@/Components/NextBase/LimeSwitch";

export const FilterRow = ({
  industries,
  salesPeople,
  pricingTiers,
}: {
  industries: NonNullable<GetClientList["response"]["industries"]>;
  salesPeople: NonNullable<GetClientList["response"]["salesPeople"]>;
  pricingTiers: NonNullable<GetClientList["response"]["pricingTiers"]>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const orderBy =
    (searchParams.get("ob") as
      | "businessName"
      | "dateCreated"
      | "lastAppointmentDate"
      | "billingCycleDate") || "dateCreated";

  const orderDirection = (searchParams.get("od") as "asc" | "desc") || "desc";

  const [search, setSearch] = useState<string>(searchParams.get("q") ?? "");
  const setSearchDebounced = useDebouncedCallback((query: string) => {
    setSearchParams((prev) => {
      prev.set("q", query);
      return prev;
    });
  }, 400);
  const handleSearchChange = (val: string) => {
    setSearch(val);
    setSearchDebounced(val);
  };

  return (
    <div className="mt-4 flex items-center justify-between">
      <div className="flex items-center gap-2">
        <LimeInput
          label="Iskanje"
          value={search}
          onValueChange={handleSearchChange}
          className="w-fit"
          size="sm"
        ></LimeInput>

        <LimeSelect
          label="Sortiraj po:"
          items={[
            { label: "Ime podjetja", key: "businessName" },
            { label: "Datum registracije", key: "dateCreated" },
            {
              label: "Datum zadnje aktivnosti",
              key: "lastAppointmentDate",
            },
            {
              label: "Datum obračuna",
              key: "billingCycleDate",
            },
          ]}
          value={orderBy}
          onSingleItemChange={(val) => {
            setSearchParams((prev) => {
              prev.set("ob", val as GetClientList["query"]["orderBy"]);
              return prev;
            });
          }}
          size="sm"
          className="w-72"
        ></LimeSelect>
        <Button
          endContent={
            <RiArrowDownLine
              style={{
                rotate: orderDirection === "desc" ? "0deg" : "180deg",
                transition: "all 0.3 cubic-bezier(0.4, 0.2, 0.1,0)",
                fontSize: "18px",
              }}
            ></RiArrowDownLine>
          }
          variant="light"
          isIconOnly
          onPress={() =>
            setSearchParams((prev) => {
              prev.set("od", orderDirection === "asc" ? "desc" : "asc");
              return prev;
            })
          }
        ></Button>
      </div>
      <AdminFilter
        industries={industries}
        salesPeople={salesPeople}
        pricingTiers={pricingTiers}
      ></AdminFilter>
    </div>
  );
};

const AdminFilter = ({
  pricingTiers,
  industries,
  salesPeople,
}: {
  pricingTiers: {
    pricingTierId: string;
    tierName: string;
  }[];
  industries: NonNullable<GetClientList["response"]["industries"]>;
  salesPeople: NonNullable<GetClientList["response"]["salesPeople"]>;
}) => {
  const [opened, setOpened] = useState(false);

  const {
    selectedIndustries,
    selectedSalesPeople,
    selectedTiers,
    showDisabled,
    shownColumns,

    setSelectedIndustries,
    setSelectedSalesPeople,
    setSelectedTiers,
    setShowDisabled,
    setShownColumns,
  } = useAdminStore((state) => state);

  const form = useForm({
    initialValues: {
      selectedTiers,
      showDisabled,
      selectedSalesPeople,
      selectedIndustries,
      shownColumns,
    },
  });

  return (
    <>
      <Button
        style={{
          background: Color.TableHeader,
        }}
        className="flex h-full justify-center p-2 px-2"
        onClick={() => setOpened(true)}
        isIconOnly
      >
        <RiListSettingsFill
          style={{ fontSize: "22px" }}
          color={Color.SecondaryText}
        ></RiListSettingsFill>
      </Button>

      <LimeDrawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Filter"
        buttons={{
          save: (
            <Button
              onClick={() => {
                setOpened(false);

                setSelectedIndustries(form.values.selectedIndustries);
                setSelectedSalesPeople(form.values.selectedSalesPeople);
                setSelectedTiers(form.values.selectedTiers);
                setShowDisabled(form.values.showDisabled);
                setShownColumns(form.values.shownColumns);
              }}
              color="primary"
            >
              Shrani
            </Button>
          ),
          cancel: (
            <Button
              variant="bordered"
              onClick={() => {
                setOpened(false);
                form.reset();
              }}
            >
              Prekliči
            </Button>
          ),
        }}
      >
        <div className="flex flex-col gap-4">
          <LimeSwitch
            title={"Prikaži onemogočene"}
            {...form.getInputProps("showDisabled", {
              type: "checkbox",
            })}
          ></LimeSwitch>

          <div>
            <Button
              onPress={() => {
                if (form.values.selectedTiers.length === pricingTiers.length) {
                  form.setFieldValue("selectedTiers", []);
                } else {
                  form.setFieldValue(
                    "selectedTiers",
                    pricingTiers.map((pt) => pt.pricingTierId),
                  );
                }
              }}
              className="w-fit"
              size="sm"
            >
              {form.values.selectedTiers.length === pricingTiers.length
                ? "Ozdberi vse"
                : "Izberi vse"}
            </Button>
            <LimeMultiSelect
              data={pricingTiers.map((pt) => ({
                label: pt.tierName,
                value: pt.pricingTierId,
              }))}
              {...form.getInputProps("selectedTiers")}
              label="Paketi"
            ></LimeMultiSelect>
          </div>

          <div>
            <Button
              onPress={() => {
                if (
                  form.values.selectedSalesPeople.length === salesPeople.length
                ) {
                  form.setFieldValue("selectedSalesPeople", []);
                } else {
                  form.setFieldValue(
                    "selectedSalesPeople",
                    salesPeople.map((sp) => sp),
                  );
                }
              }}
              className="w-fit"
              size="sm"
            >
              {form.values.selectedSalesPeople.length === salesPeople.length
                ? "Ozdberi vse"
                : "Izberi vse"}
            </Button>
            <LimeMultiSelect
              data={salesPeople.map((sp) => ({ label: sp, value: sp }))}
              {...form.getInputProps("selectedSalesPeople")}
              label="Sales people"
            ></LimeMultiSelect>
          </div>

          <div>
            {" "}
            <Button
              onPress={() => {
                if (
                  form.values.selectedIndustries.length === industries.length
                ) {
                  form.setFieldValue("selectedIndustries", []);
                } else {
                  form.setFieldValue(
                    "selectedIndustries",
                    industries.map((i) => i),
                  );
                }
              }}
              className="w-fit"
              size="sm"
            >
              {form.values.selectedIndustries.length === industries.length
                ? "Ozdberi vse"
                : "Izberi vse"}
            </Button>
            <LimeMultiSelect
              data={industries.map((i) => i)}
              {...form.getInputProps("selectedIndustries")}
              label="Industrija"
            ></LimeMultiSelect>
          </div>

          <LimeMultiSelect
            data={columnsArray.map((c) => ({ label: c, value: c }))}
            label="Prikazani stolpci"
            {...form.getInputProps("shownColumns")}
          ></LimeMultiSelect>
        </div>
      </LimeDrawer>
    </>
  );
};

// export type FilterFormValues = {
//   selectedTiers: NonNullable<GetClientList["query"]["tiers"]>;
//   showDisabled: boolean;
//   selectedSalesPeople: GetClientList["query"]["selectedSalesPeople"];
//   selectedIndustries: GetClientList["query"]["selectedIndustries"];
//   shownColumns: ShownColumns;
// };
